.marker {
  position: absolute;
  top: 50%;
  left: 50%;
 
  width: 60px;
  height: 40px;
  border-radius: 10px;
  background: #2e2e2e;
  user-select: none;
  transform: translate(-50%, -50%);
}
.marker:hover {
  z-index: 1;
}

.map-marker{
  width: 60px;
  height: 40px;
  border-radius: 10px;
  background: #00cae9;
}

.pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #00cae9;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}
.pin:after {
  content: "";
  width: 14px;
  height: 14px;
  margin: 8px 0 0 8px;
  background: #e6e6e6;
  position: absolute;
  border-radius: 50%;
}

.bounce {
  animation-name: bounce;
  animation-fill-mode: both;
  animation-duration: 1s;
}



.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  background-color: #EDF2F7;
  border-radius: 10px;
  padding-left: 10px;
  width:120px;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: translateY(-2000px) rotate(-45deg);
  }

  60% {
    opacity: 1;
    transform: translateY(30px) rotate(-45deg);
  }

  80% {
    transform: translateY(-10px) rotate(-45deg);
  }

  100% {
    transform: translateY(0) rotate(-45deg);
  }
}
